import { uniqueId } from 'cadenza/utils/unique-id';
import { cadenzaUrl } from 'cadenza/utils/cadenza-url/cadenza-url';
import { logger } from 'cadenza/utils/logging';

const UNLOAD_EVENT = 'unload';

/**
 * An AbortController with an ID, which sends a beacon to the backend on abort to cancel running backend tasks.
 *
 * Clients should add the ID to their (abortable) backend requests.
 *
 * For many use cases, the request should also be "single execution".
 * In that case, don't use this directly, but use api.js {@link singleExecution} instead.
 * If a merely cancelable request is needed, without single execution behavior, then use this.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon|Navigator.sendBeacon()}
 */
export class BackendAbortController extends AbortController {
  _abortOnUnloadCounter: number;

  override readonly signal!: BackendAbortSignal;

  constructor() {
    super();
    this.signal.id = uniqueId();
    this._abortOnUnloadCounter = 0;

    const superAbort = this.abort;
    this.abort = () => {
      if (this.signal.aborted) {
        return;
      }

      superAbort.call(this);
      this._abortOnUnloadCounter = 0;
      window.removeEventListener(UNLOAD_EVENT, this.abort);

      if (!this.signal.finished) {
        cancelWorkOrder(this.signal.id);
      }
    };
  }

  /**
   * `true` adds a listener to the window 'unload' event, which aborts the controller.
   * `false` removes the listener again.
   *
   * If _multiple_ clients set `abortOnUnload = true`, *only one* listener is added.
   * _All_ clients must set it to `false` to remove the listener.
   *
   * If a Promise is set, `abortOnUnload` is automatically set to `false`
   * when the Promise is resolved or rejected.
   */
  set abortOnUnload(abort: boolean | Promise<unknown>) {
    if (this.signal.aborted) {
      return;
    }
    if (abort) {
      this._abortOnUnloadCounter++;
      if (this._abortOnUnloadCounter === 1) {
        window.addEventListener(UNLOAD_EVENT, this.abort);
      }
      if (abort instanceof Promise) {
        abort
          /**
           * As this is an additional promise handler run in parallel to the main one, we suppress
           * error logs here, so that they are not logged redundantly with "Uncaught (in promise)".
           * This is especially important for AbortErrors which usually should not be logged at all.
           */
          .catch((_) => {
            /* ignore because not main handler */
          })
          .finally(() => {
            this.abortOnUnload = false;
          });
      }
    } else if (this._abortOnUnloadCounter > 0) {
      this._abortOnUnloadCounter--;
      if (this._abortOnUnloadCounter === 0) {
        window.removeEventListener(UNLOAD_EVENT, this.abort);
      }
    }
  }

  get abortOnUnload(): boolean {
    return this._abortOnUnloadCounter > 0;
  }
}

type BackendAbortSignal = AbortSignal & { id: string; finished?: boolean };

export function isBackendAbortSignal(signal?: AbortSignal): signal is BackendAbortSignal {
  return (signal as BackendAbortSignal)?.id != null;
}

export function cancelWorkOrder(cancelId: string) {
  const url = `/workOrder/${cancelId}/state`;
  if (!navigator.sendBeacon(cadenzaUrl(url), 'canceled')) {
    logger.warn(`Failed to abort backend tasks for ID "${cancelId}"`);
  }
}
