// DO NOT REMOVE: Since the d-alert component is part of the base bundle, this makes sure the web component is registered in both the iframe and the parent
import 'ui/dialog/alert/alert-dialog';
import type { AlertDialog, AlertDialogType } from 'ui/dialog/alert/alert-dialog';
import type { ModalSize } from 'ui/dialog/modal/modal';

import { cadenzaTopWindow } from 'cadenza/utils/iframe';
import { assertNonNullable } from 'cadenza/utils/custom-error';
import { on } from 'cadenza/utils/event-util';

interface AlertOptions {
  submit?: string;
  alternativeSubmit?: string;
  type?: AlertDialogType;
  size?: ModalSize;
  cancel?: boolean | string;
  resolveImmediately?: boolean;
}

interface ConfirmOptions extends AlertOptions {
  submitCallback?: SubmitCallback;
  alternativeSubmitCallback?: SubmitCallback;
  loadingText?: string;
  alternativeLoadingText?: string;
  confirmDialogCallback?: (alertDialog: AlertDialog) => void;
  styleClass?: string | string[];
  shouldCloseOnLoadEnd?: boolean;
}

interface InternalAlertOptions extends ConfirmOptions {
  message: string | Node;
}

/**
 * @function SubmitCallback
 * @return {Promise} - promise to be passed to modal's setLoading method
 */
export type SubmitCallback = (alert: AlertDialog) => Promise<unknown>;

/**
 * Shows an alert modal dialog.
 *
 * @param title - Dialog title.
 * @param message - Dialog content message.
 * @param options - Additional options.
 * @param [options.submit] - Text for the submit button.
 * @param [options.alternativeSubmit] - Text for the alternative submit button.
 * @param [options.type='info'] - Alert dialog type.
 * @param [options.cancel] - Whether cancel is allowed. If true, also a cancel button is added.
 * @param [options.resolveImmediately] - If true the promise will be resolved immediately, when the confirmation dialog is being closed, not waiting till the closing animation is done.
 * @param [options.shouldCloseOnLoadEnd] - If false the dialog alert won't close after submit for error handling. You have to close it with alert.close(true).
 * @return A promise resolved, when the user has confirmed or rejected the alert.
 */
export function alert(title: string, message: string | Node = '', options: AlertOptions = {}) {
  return _alert(title, { type: 'info', message, ...options });
}

export function errorAlert(title: string, message: string | Node = '', options: AlertOptions = {}) {
  options.type = 'error';
  return alert(title, message, options); // NOSONAR because not a native alert
}

/**
 * Shows a confirmation modal dialog.
 *
 * @param title - Dialog title.
 * @param message - Dialog content message.
 * @param options - Additional options.
 * @param [options.submit] - Text for the submit button.
 * @param [options.alternativeSubmit] - Text for the alternative submit button.
 * @param [options.type] - Alert dialog type.
 * @param [options.cancel=false] - Whether cancel is allowed. If truthy, a cancel button is added. If string, given string is used as text of cancel button.
 * @param [options.resolveImmediately=false] - If true the promise will be resolved immediately, when the confirmation dialog is being closed, not waiting till the closing animation is done.
 * @param [options.submitCallback] - On submit (confirm), AlertDialog executes function and sets loading state for returned promise.
 * @param [options.alternativeSubmitCallback] - On alternative submit button, AlertDialog executes function and sets loading state for returned promise.
 * @param [options.loadingText] - text to display while submitAction is in progress.
 * @param [options.alternativeLoadingText] - text to display while alternative submit is in progress.
 * @param [options.confirmDialogCallback] - Callback to get the confirmDialog instance to access it later (for changing loading text etc)
 * @param [options.styleClass] - Optional additional css class to add to the dialog
 * @return A promise resolved, when the user has confirmed or rejected the alert
 */
export function confirm(
  title: string | Node,
  message: string | Node,
  options: ConfirmOptions = {},
): Promise<boolean | undefined> {
  const {
    submit,
    alternativeSubmit,
    type = 'info',
    size,
    cancel = true,
    resolveImmediately = false,
    submitCallback,
    alternativeSubmitCallback,
    loadingText,
    alternativeLoadingText,
    confirmDialogCallback = () => {
      /* do nothing */
    },
    styleClass,
    shouldCloseOnLoadEnd,
  } = options;
  return _alert(title, {
    message,
    submit,
    alternativeSubmit,
    type,
    size,
    cancel,
    resolveImmediately,
    submitCallback,
    alternativeSubmitCallback,
    loadingText,
    alternativeLoadingText,
    confirmDialogCallback,
    styleClass,
    shouldCloseOnLoadEnd,
  });
}

function _alert(title: string | Node, options: InternalAlertOptions): Promise<boolean | undefined> {
  const {
    confirmDialogCallback = () => {
      /* Default: do nothing */
    },
  } = options;

  // Get the AlertDialog web component constructor from the parent window (cadenzaTopWindow) instead of from the iframe window.
  // This is important because the element will be appended to 'cadenzaTopWindow.document.body', i.e. the parent window
  // and if we create the element inside of the iframe and append it in the parent.window, the prototype chain wouldn't be correctly inherited in Firefox
  const AlertDialogLocal = cadenzaTopWindow.customElements.get('alert-dialog');
  assertNonNullable(AlertDialogLocal);

  return new Promise((resolve) => {
    const dialog = new AlertDialogLocal(title, options) as AlertDialog;

    on(dialog, 'cancel', () => {
      // Cancel resolved with undefined because false is reserved for alternative submit.
      // The logic is: submit - true, alternativeSubmit - false, cancel - undefined.
      resolve(undefined);
    });

    on(dialog, 'closing', () => {
      if (options.resolveImmediately) {
        resolve(dialog.returnValue);
      }
    });

    on(dialog, 'close', () => {
      resolve(dialog.returnValue);
    });

    confirmDialogCallback(dialog);
    dialog.connectAndShow(cadenzaTopWindow.document.body);
  });
}
