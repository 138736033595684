const el = document.createElement('div');

export function escapeHTML(value: string | undefined) {
  el.textContent = value ?? null;
  return el.innerHTML;
}

export function escapeForAttribute(string: string) {
  return escapeHTML(string).replaceAll('"', '&quot;').replaceAll("'", '&#39;');
}
