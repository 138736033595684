/**
 * This file intends to hold all of our parameter names to be used in our javascript api uniformly,
 * as well as custom media types.
 *
 * @see net/disy/cadenza/web/api/WebConstants.java
 * @see net/disy/lib/mediatype/MediaTypeValue.java
 */

export const MIMETYPE_DISY_ROUTE = 'application/vnd.disy.route+json';
export const MIMETYPE_DISY_ISOCHRONE = 'application/vnd.disy.isochrone+json';
export const MIMETYPE_DISY_TIMELINE = 'application/vnd.disy.timeline+json';
export const MIMETYPE_DISY_GRID_SQUARE = 'application/vnd.disy.grid.square+json';

/* Accept header values to distinguish between "/layers/{layerId}/features" endpoints */
export const MIMETYPE_DISY_VECTOR_LAYER_FEATURES = 'application/vnd.disy.vector-layer.features+json';
export const MIMETYPE_DISY_WORKORDER_ID = 'application/vnd.disy.workorder-id+text';

export const PARAMETER_DPI = 'DPI';

/**
 * @deprecated Prefer to use `HEADER_DISY_CANCEL_ID` as having the cancelId as a request param
 * essentially makes the request non-cacheable.
 */
export const PARAMETER_CANCEL_ID = 'cancelId';
export const EXECUTION_CONFIRMED = 'executionConfirmed';
export const PARAMETER_MAP_SCALE = 'mapScale';
export const PARAMETER_MAP_EXTENT = 'mapExtent';
export const PARAMETER_MAP_SIZE = 'mapSize';
export const PARAMETER_MAP_RESOLUTION = 'mapResolution';
export const PARAMETER_SRS_CODE = 'srsCode';
export const PARAMETER_GEOMETRY = 'geometry';

export const HEADER_DISY_UPLOAD_TYPE = 'X-Disy-Upload-Type';
export const HEADER_DISY_MESSAGES = 'X-Disy-Messages';
export const HEADER_EXPORT_TYPE = 'X-Disy-Export-Type';
export const HEADER_POLL_REQUEST = 'X-Poll-Request';
export const HEADER_DISY_PROBLEM = 'X-Disy-Problem';
export const HEADER_REQUESTED_WITH = 'X-Requested-With';

/**
 * @deprecated This is added automatically to an `ajax()` request
 * if you pass the `AbortSignal` of a `BackendAbortController`.
 */
export const HEADER_DISY_CANCEL_ID = 'X-Disy-Cancel-Id';

/* data URL with transparent PNG that can be used as image src for cancelling the loading of an image */
export const BLANK_IMAGE_SRC =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
